<template>
    <b-card no-body border-variant="primary">
        <b-card-body class="py-1">
            <b-row
                >
                <b-col
                    cols="12"
                    md="1"
                    class="text-center align-content-center"
                >
                    <rt-icon icon="files" variant="light" :size="30" />
                </b-col>
                <b-col
                    md="10"
                    cols="12"
                >
                    <h4 class="cursor-pointer">{{ serviceCharge.text}}</h4>
                    <rt-icon icon="house" variant="light mr-50"/>
                    <small 
                        v-for="(property, index) in serviceCharge['charges_data']"
                        class="text-truncate mr-50"
                    >
                        <b>{{ getPropertyName(property['property_id'])}}</b>  {{property['thousandth_formula'] }}
                    </small>
                </b-col>
                <b-col
                    cols="12"
                    md="1"
                    sm="12"
                >
                    <div class="float-right">
                        <b-button-group
                            size="sm"
                            >
                            <b-button
                                variant="outline-primary"
                                @click="onEdit"
                                >
                                <rt-icon icon="edit" variant="light" :size="16"/>
                            </b-button>
                            <b-button
                                variant="outline-primary"
                                @click="onDelete"
                            >
                                <rt-icon icon="trash" variant="light" :size="16"/>
                            </b-button>
                        </b-button-group>
                    </div>
                </b-col>
            </b-row>
        </b-card-body>
  </b-card>
</template>

<script>

import {
  VBTooltip,
  BCard,
  BCardBody,
  BLink,
  BCol,
  BRow,
  BBadge,
} from "bootstrap-vue";

export default {
    components: {
        VBTooltip,
        BCard,
        BCardBody,
        BLink,
        BCol,
        BRow,
        BBadge,
    },
    props: {
      serviceCharge: {
        type: Object,
        default: null,
      },
      properties: {
        type: Array,
        default: []
      }
    },
    methods: {
        onEdit() {
            this.$emit('edit', this.serviceCharge)
        },
        onDelete(){
            this.$emit('delete', this.serviceCharge)
        },
        getPropertyName(id){
            const property = this.properties.find(property => property.value === id)
            return property ? property.text : ''
        }
    }
}


</script>