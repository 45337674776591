<template>
    <b-modal
        id="modal-service-charge"
        :ok-title="i18nT(`Sauvegarder`)"
        size="lg"
        :title="i18nT(`Clé de répartition`)"
        @ok="validationForm"
        @hidden="resetModal"
        @shown="showModal"
    >
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col
                    cols="12"
                    class="pl-5 pr-5 mt-2"
                >
                    <b-row>
                        <b-col
                            cols="12"
                        >
                            <b-form-group
                                :label="i18nT(`Identifiant`)"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="i18nT(`Identifiant`)"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="Identifier"
                                        v-model="identifier"
                                        :state="errors.length > 0 ? false:null"
                                    />
                                    <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                    <small v-if="!errors[0]">{{ i18nT(`Saisir un identifiant, référence ou numéro unique.`) }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-row v-for="(selectedProperty, index) in selectedProperties" :key="'property' + index" class="mb-3">
                                <b-col
                                    cols="12"
                                >
                                    <b-form-group
                                        :label="i18nT(`Lot `) + (index + 1)"
                                        :class="{ 'required': index===0 }"
                                    >
                                        <validation-provider
                                            :name="i18nT(`Lot`)"
                                            :rules="index===0 ? 'required': ''"
                                            #default="{ errors }"
                                        >
                                            <v-select
                                                v-model="selectedProperty.value"
                                                :options="properties"
                                                label="text"
                                                @input="disableSelectArray('selectedProperties', `properties`)"
                                                :placeholder="i18nT(`Choisir`)"
                                                :clearable="false"
                                                :reduce="e=>e.value"
                                                :selectable="selected => selected.disabled !== true"
                                            />
                                            <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12">
                                    <b-form-group :label="i18nT(`Tantièmes du lot `) + (index + 1)"  :class="{ 'required': index===0 }">
                                        <validation-provider :name="i18nT(`Tantièmes du lot `) + (index + 1)"  :rules="index===0 ? 'required': ''" #default="{errors}">
                                            <b-form-input
                                                v-model="selectedProperty.text"
                                                :placeholder="i18nT(`Ex. 100/1000, 10%`)"
                                                :state="errors.length > 0 ? false:null"
                                            />
                                            <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="12" v-if="index!==0">
                                    <b-button variant="outline-danger" @click="removeProperty(index)">{{ i18nT(`Supprimer l'élément`) }}</b-button>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols='12'>
                                    <b-button @click="addProperty">
                                        <rt-icon icon="circle-plus" variant="light"/>
                                        {{ i18nT(`Ajouter un élément`) }}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>

</template>

<script>

import {
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
    components: {
        BRow,
        BCol,
        BModal,
        BFormGroup,
        BFormInput,
        vSelect,
        ValidationProvider,
        ValidationObserver,
    }, 
    props: {
        serviceCharge: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            required,
            selectedProperties: [{}],
            properties: [],
            identifier: null,
            currentServiceCharge: null
        }
    },
    methods: {
        showModal() {
            if(this.properties.length === 0) {
                this.$http.get('landlord/properties/list').then(({data}) => {
                    console.log(data)
                    this.properties = data.data.map(property => {
                        return {
                            value: property.id,
                            text: property.label,
                            disabled: false
                        }
                    })
                })
            }
            if(!this.serviceCharge){
                this.properties = this.properties.map(property => {
                    return {
                        value: property.value,
                        text: property.text,
                        disabled: false
                    }
                });
            }
        },
        addProperty() {
            this.selectedProperties.push({property: {}});
        },
        removeProperty(index) {
            this.selectedProperties.splice(index, 1);
        },
        disableSelectArray(array, options) {
            this[options].forEach(option => option.disabled = false);

            this[array].map(item => {
                for (let i = 0; i < this[options].length; i++) {
                    if(item.value && this[options][i].value === item.value) {
                        this[options][i].disabled = true
                    }
                }
            });
        },
        validationForm(e) {
            e.preventDefault();
            this.$refs.simpleRules.validateWithInfo().then(({isValid, errors, fields})=>{
                if(isValid){
                    this.currentServiceCharge = {
                        text: this.identifier,
                        charges_data: this.selectedProperties.map(property => {
                            return {
                                property_id: property.value,
                                thousandth_formula: property.text
                            }
                        })
                    }
                    this.serviceCharge ? this.currentServiceCharge.id = this.serviceCharge.id : null;
                    this.$emit('updateServiceCharge', this.currentServiceCharge);
                    this.resetModal();
                }else {
                    let y = 1000000
                    let prevY = 1000000
                    for (const key in errors) {
                        if (errors[key].length > 0) {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: errors[key][0],
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                            // console.log(fields[key])
                            const el = document.getElementById(fields[key].id)
                            prevY =
                                el.getBoundingClientRect().top + window.scrollY - 150
                            if (prevY <= y) y = prevY
                        }
                    }
                    if (y < 1000000) {
                        window.scrollTo({ top: y, behavior: 'smooth' })
                    }
                }
            })

        },
        resetModal() {
            this.$bvModal.hide('modal-service-charge');
            this.currentServiceCharge = null;
            this.selectedProperties = [{}];
            this.identifier = null;
            this.$emit('resetModal', null);

        }
    },
    watch: {
        serviceCharge: {
            handler: function(val) {

                if(val) {
                    this.identifier = val.text
                    this.selectedProperties = val['charges_data'].map(charge => {
                        return {
                            value: charge['property_id'],
                            text: charge['thousandth_formula']
                        }
                    })
                    this.disableSelectArray('selectedProperties', 'properties')
                }
            },
            deep: true
        }
    }
}

</script>