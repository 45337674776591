<template>
    <div>
        <ellipsis-dropdown 
            componentName="buildings"
            :showDeleteBtn="currentBuildingId !== undefined"
            @save="validationForm"
            @delete="onDelete"
        />
        <validation-observer ref="simpleRules">
            <b-tabs
                v-model="tabIndex"
                no-nav-style
                vertical
                content-class="col-12 col-md-9 mt-1 mt-md-0"
                nav-wrapper-class="col-md-3 col-12"
                nav-class="nav-left"
            >
                <b-tab
                    @click="changeTab('general-info')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                                <RtIcon :size="21" variant="light" icon="circle-info"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Informations générales`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card no-body>
                        <b-row class="mx-1 mb-2 pb-2 border-bottom">
                            <b-col
                                cols="12"
                            >
                                <h2 class="mb-2 mt-2">
                                    {{i18nT(`Informations générales`)}}
                                </h2>
                            </b-col>
                            <b-col
                                cols="12"
                                md="9"
                                class="pl-5"
                            >
                                <b-row class="mt-2">
                                    <b-col
                                        cols="12">
                                        <b-form-group
                                            :label="i18nT(`Identifiant`)"
                                            class="required"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Identifiant`)"
                                                #default="{ errors }"
                                                rules="required"
                                            >
                                                <b-form-input
                                                    id="Identifiant"
                                                    v-model="buildingData.BuildingAddress"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                                <small v-if="!errors[0]">{{ i18nT(`Saisir un identifiant, référence ou numéro unique.`) }}</small>
                                            </validation-provider>
                                        </b-form-group>

                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col
                                        cols="12"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Adresse`)"
                                            class="required"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`Adresse`)"
                                                rules="required"
                                            >
                                                <b-form-input
                                                    id="Adresse"
                                                    v-model="buildingData.BuildingActualAddress"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Adresse 2`)"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`Adresse 2`)"
                                            >
                                                <b-form-input
                                                    id="address2"
                                                    v-model="buildingData.BuildingActualAddress2"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Ville`)"
                                            class="required"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`Ville`)"
                                                rules="required"
                                            >
                                                <b-form-input
                                                    id="Ville"
                                                    v-model="buildingData.BuildingActualCity"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Code postal`)"
                                            class="required"
                                        >
                                            <validation-provider
                                                #default="{ errors }"
                                                :name="i18nT(`Code postal`)"
                                                rules="required"
                                            >
                                                <b-form-input
                                                    id="Code postal"
                                                    v-model="buildingData.BuildingActualZip"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Région`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Région`)"
                                            >
                                                <b-form-input
                                                    id="state"
                                                    v-model="buildingData.BuildingActualState"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Pays`)"
                                            class="required"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Pays`)"
                                                #default="{ errors }"
                                                rules="required"
                                            >
                                                <rt-select
                                                    id="Pays"
                                                    v-model="buildingData.BuildingActualCountry"
                                                    :options="countries"
                                                    label="text"
                                                    :clearable="false"
                                                    :reduce="(e) => e.value"
                                                    class="required"
                                                    :state="errors.length > 0 ? false:null"
                                                />
                                                <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1 mb-2 pb-2 border-bottom">
                            <b-col
                                cols="12"
                            >
                                <h2 class="mb-3 mt-2">
                                    {{i18nT(`Description`)}}
                                </h2>
                            </b-col>
                            <b-col
                                cols="12"
                                md="9"
                                class="pl-5"
                            >
                                <b-row >
                                    <b-col
                                        cols="12">
                                        <b-form-group
                                            :label="i18nT(`Superficie m2`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Superficie m2`)"
                                            >
                                                <b-form-input
                                                    id="area"
                                                    v-model="buildingData.BuildingSize"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col
                                        cols="12"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Année de construction`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Année de construction`)"
                                            >
                                                <b-form-input
                                                    id="constructionYear"
                                                    v-model="buildingData.BuildingConstructionYear"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Description`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Description`)"
                                            >
                                                <b-form-textarea
                                                    id="description"
                                                    v-model="buildingData.BuildingComments"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Note privée`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Note privée`)"
                                            >
                                                <b-form-textarea
                                                    id="privateNote"
                                                    v-model="buildingData.BuildingNotes"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="buildingData.BuildingCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ buildingData.BuildingCreateTime | date }}
                                        </small>
                                    </div>
                                    <div v-if="buildingData.BuildingLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ buildingData.BuildingLastEditDate | date}}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'buildings'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                    {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>
                <b-tab
                    @click="changeTab('lots')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="door-open"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Lots`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card no-body>
                        <b-row class="mx-1 mb-2 pb-2 border-bottom">
                            <b-col
                                cols="12"
                            >
                                <h2 class="mb-2 mt-2">
                                    {{i18nT(`Lots`)}}
                                </h2>
                            </b-col>
                            <b-col
                                cols="12"
                                md="9"
                                class="pl-5"
                            >
                                <b-row class="mt-2"
                                    v-for="(buildingProperty, index) in buildingProperties"
                                    :key="'lot' + index"
                                >
                                    <b-col
                                        cols="12"
                                    >
                                        <b-row>
                                            <b-col
                                                cols="12" 
                                            >
                                                <b-form-group
                                                    :label="i18nT(`Lot `) + (index + 1)"
                                                >
                                                    <validation-provider
                                                        :name="i18nT(`lot`) + index"
                                                    >
                                                        <v-select
                                                            v-model="buildingProperty.value"
                                                            :options="properties"
                                                            @input="disableSelectArray('buildingProperties', `properties`)"
                                                            :clearable="false"
                                                            :selectable="selected => selected.disabled !== true"
                                                            label="text"
                                                            :reduce="(e) => e.value"   
                                                            :placeholder="i18nT(`Choisir`)"                                        
                                                        />
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                                cols="12"
                                            >
                                                <b-form-group :label="i18nT(`Tantièmes du lot `) + (index + 1)">
                                                    <validation-provider :name="i18nT(`Tantièmes du lot `) + (index + 1)">
                                                        <b-form-input
                                                            v-model="propertyThousandths[buildingProperty.value]"
                                                            :placeholder="i18nT(`Ex. 100/1000, 10%`)"
                                                        />
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col
                                            >
                                                <b-button variant="outline-danger" @click="removeProperty(index)">{{ i18nT(`Supprimer l'élément`) }}</b-button>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-3">
                                    <b-col cols='12'>
                                        <b-button v-if="buildingProperties.length < properties.length" @click="addProperty">
                                            <rt-icon icon="circle-plus" variant="light"/>
                                            {{ i18nT(`Ajouter un élément`) }}
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="buildingData.BuildingCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ buildingData.BuildingCreateTime | date }}
                                        </small>
                                    </div>
                                    <div v-if="buildingData.BuildingLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ buildingData.BuildingLastEditDate | date}}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'buildings'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                    {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>
                <b-tab
                    @click="changeTab('additional-info')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="sitemap"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Informations complémentaires`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card no-body>
                        <b-row class="mx-1 mb-2 pb-2 border-bottom">
                            <b-col
                                cols="12"
                            >
                                <h2 class="mb-2 mt-2">
                                    {{i18nT(`Désignation des parties et équipements`)}}
                                </h2>
                            </b-col>
                            <b-col
                                cols="12"
                                md="9"
                                class="pl-5 mt-4"
                            >
                                <b-form-group
                                >
                                    <b-form-checkbox
                                        v-for="(option, index) in extras"
                                        cols="12"
                                        :key="option.label + ' ' + index"
                                        v-model="selectedExtras"
                                        :value="option.value"
                                        inline
                                    >
                                        <div class="mr-2 mb-2 rt-custom-checkbox text-nowrap">{{ option.text }}</div>
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="buildingData.BuildingCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ buildingData.BuildingCreateTime | date }}
                                        </small>
                                    </div>
                                    <div v-if="buildingData.BuildingLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ buildingData.BuildingLastEditDate | date}}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'buildings'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                    {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>
                <b-tab
                    @click="changeTab('financial-info')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="coins"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Informations financières`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card no-body>
                        <b-row class="mx-1 mb-2 pb-2 border-bottom">
                            <b-col
                                cols="12"
                            >
                                <h2 class="mb-2 mt-2">
                                    {{i18nT(`Informations financières`)}}
                                </h2>
                            </b-col>
                            <b-col
                                cols="12"
                                md="9"
                                class="pl-5"
                            >
                                <b-row class="mt-2">
                                    <b-col
                                        cols="12">
                                        <b-form-group
                                            :label="i18nT(`Date d'acquisition`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Acquisition Date`)"
                                            >
                                                <date-picker
                                                    v-model="buildingData.BuildingAcquisitionDate"
                                                    reset-button
                                                    :manual-input="true"
                                                />
                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Prix d’acquisition`)"
                                        > 
                                            <validation-provider
                                                :name="i18nT(`Initial Price`)"
                                            >
                                                <b-input-group
                                                    prepend="EUR"
                                                    class="border-right-none mb-50"
                                                >
                                                    <b-form-input
                                                        id="buingPrice"
                                                        type="number"
                                                        v-model="buildingData.BuildingInitialPrice"
                                                    />
                                                </b-input-group>
                                            </validation-provider>
                                            <small>{{ i18nT(`Montant utilisé pour calculer la rentabilité dans la rubrique Bilan.`) }}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        cols="12"
                                    >
                                        <b-form-group
                                            :label="i18nT(`Frais d'acquisition`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Acquisition Costs`)"
                                            >
                                                <b-input-group
                                                    prepend="EUR"
                                                    class="border-right-none mb-50"
                                                >
                                                    <b-form-input
                                                        id="acquisitionFees"
                                                        type="number"
                                                        v-model="buildingData.BuildingAcquisitionCosts"
                                                    />
                                                </b-input-group>
                                            </validation-provider>
                                            <small>{{ i18nT(`Montant d'acquisition (frais de notaire, frais d'inscription etc.).`) }}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="mx-1 mb-2 pb-2 border-bottom">
                            <b-col
                                cols="12"
                            >
                                <h2 class="mb-2 mt-2">
                                    {{i18nT(`Informations fiscales`)}}
                                </h2>
                            </b-col>
                            <b-col
                                cols="12"
                                md="9"
                                class="pl-5"
                            >
                                <b-row>
                                    <b-col
                                        cols="12">
                                        <b-form-group
                                            :label="i18nT(`Taxe foncière`)"
                                        >
                                            <validation-provider
                                                :name="i18nT(`Annual Tax`)"
                                            >
                                                <b-input-group
                                                    prepend="EUR"
                                                    class="border-right-none mb-50"
                                                >
                                                    <b-form-input
                                                        id="buildingTax"
                                                        type="number"
                                                        v-model="buildingData.BuildingAnnualTax"
                                                    />
                                                </b-input-group>
                                            </validation-provider>
                                            <small>{{ i18nT(`Montant repris à titre indicatif dans la fiche du bien.`) }}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="buildingData.BuildingCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ buildingData.BuildingCreateTime | date }}
                                        </small>
                                    </div>
                                    <div v-if="buildingData.BuildingLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ buildingData.BuildingLastEditDate | date}}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'buildings'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                    {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>
                <b-tab
                    @click="changeTab('keys-and-digital')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="key-skeleton"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Clés et digicode`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card no-body>
                        <b-row class="mx-1 mb-2 pb-2" :class="{'border-bottom': keysAndCodes.length > 0}">
                            <b-col
                                cols="12"
                            >
                                <h2 class="mb-2 mt-2">
                                    {{i18nT(`Clés et digicode`)}}
                                </h2>
                            </b-col>
                            <b-col
                                cols="12"
                                md="10"
                                class="pl-5"
                            >
                                <b-row :class="{'mt-3': index === 0}" v-for="(code, index) in keysAndCodes" :key="'key' + index" v-if="!code.BuildingKeyDelete">
                                    <b-col
                                        cols="12"
                                        md="3" 
                                        class="mt-1"
                                    >
                                        <validation-provider
                                            :name="i18nT(`Description`)"
                                        >
                                            <b-form-input
                                                id="description"
                                                v-model="code.BuildingKeyDescription"
                                                :placeholder="i18nT(`Description`)"
                                            />
                                        </validation-provider>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="3"
                                        class="mt-1"
                                    >
                                        <validation-provider
                                            :name="i18nT(`Number`)"
                                        >
                                            <b-input-group
                                                prepend="#"
                                                class="border-right-none mb-50"
                                            >
                                                <b-form-input
                                                    id="keyNumber"
                                                    type="number"
                                                    v-model="code.BuildingKeyNumber"
                                                    :placeholder="i18nT(`Numéro`)"
                                                />
                                            </b-input-group>
                                        </validation-provider>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="2"
                                        class="mt-1"
                                    >
                                        <validation-provider
                                            :name="i18nT(`quantity`)"
                                        >
                                            <b-form-input
                                                id="Quantity"
                                                type="number"
                                                v-model="code.BuildingKeyQuantity"
                                                :placeholder="i18nT(`Qté`)"
                                            />
                                        </validation-provider>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        md="4"
                                        class="mt-1"
                                    >
                                        <validation-provider
                                            :name="i18nT(`Comment`)"
                                        >
                                            <b-form-input
                                                id="comment"
                                                v-model="code.BuildingKeyComments"
                                                :placeholder="i18nT(`Commentaire`)"
                                            />
                                        </validation-provider>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        class="text-right mt-1"
                                    >
                                        <b-button variant="outline-danger" @click="removeKeyAndCode(index)">{{ i18nT(`Supprimer l'élément`) }}</b-button>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-3">
                                    <b-col
                                        cols="12"
                                        md="9"
                                    >
                                        <b-button @click="addKeyAndCode">
                                            <rt-icon icon="circle-plus" variant="light"/>
                                            {{ i18nT(`Ajouter un élément`) }}
                                        </b-button>
                                        <p>{{ i18nT(`Vous pouvez ajouter plusieurs clés et codes d'accès si besoin.`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="buildingData.BuildingCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ buildingData.BuildingCreateTime | date }}
                                        </small>
                                    </div>
                                    <div v-if="buildingData.BuildingLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ buildingData.BuildingLastEditDate | date}}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'buildings'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                    {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>
                <b-tab
                    @click="changeTab('service-charge')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="split"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Clés de répartition`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card no-body>
                        <b-row class="mx-1 mb-2 pb-2 border-bottom">
                            <b-col
                                cols="12"
                            >
                                <h2 class="mb-2 mt-2">
                                    {{i18nT(`Clés de répartition`)}}
                                </h2>
                            </b-col>
                            <b-col
                                cols="12"
                                md="9"
                                class="pl-5"
                            >   
                                <b-row class="mt-1">
                                    <b-col cols=12 v-for="(serviceCharge, index) in serviceCharges" :key="'serviceCaharge' + index" v-if="!serviceCharge.delete">
                                        <service-charge-card :serviceCharge="serviceCharge" :properties="properties" @edit="editServiceCharge(serviceCharge,index)" @delete="deleteServiceCharge" />
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols='12'>
                                        <b-button v-b-modal.modal-service-charge>
                                            <rt-icon icon="circle-plus" variant="light"/>
                                            {{ i18nT(`Ajouter un élément`) }}
                                        </b-button>
                                        <p>{{ i18nT(`Vous pouvez ajouter plusieurs grilles de répartition des charges`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="buildingData.BuildingCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ buildingData.BuildingCreateTime | date }}
                                        </small>
                                    </div>
                                    <div v-if="buildingData.BuildingLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ buildingData.BuildingLastEditDate | date}}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'buildings'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                    {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>
                <b-tab
                    @click="changeTab('pictures')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="camera"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Photos`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card no-body>
                        <b-row class=" mr-1 ml-1 mb-2 pb-2 border-bottom">
                            <b-col cols="12"
                            >
                                <h2 class="mb-2 mt-2">
                                    {{i18nT(`Photos`)}}
                                </h2>
                            </b-col>
                            <b-col cols="12"
                                class="pt-1 pl-5"
                            >
                                <vue-dropzone ref="photoDropzone"
                                    :duplicateCheck="true"
                                    @vdropzone-queue-complete="queCompleteMultiple"
                                    @vdropzone-error="dropzoneErrorMultiple"
                                    @vdropzone-success="dropzoneSuccessMultiple"
                                    id="dz-photos-multiple"
                                    class="ren-dropzone"
                                    :options="dropzoneOptions"
                                >
                                </vue-dropzone>
                                <draggable
                                    v-model="photos"
                                    tag="div"
                                    class="d-flex flex-wrap"
                                    @end="updatePhotoOrder"
                                >
                                            <div class="rt-img-container mt-2 mr-2 text-center" @click="openPreviewModal(photo)"
                                                 v-for="(photo, index) in photos"
                                                 :key="'Photo' + index"
                                            >
                                                <div class="rt-span" @click.stop="setCoverImage(photo)">
                                                    <span v-b-tooltip="i18nT(`Couverture`)">
                                                        <RtIcon :variant="photo.BuildingCoverImage ? 'solid' : 'regular'" icon="star rt-star-icon" :size="18" />
                                                    </span>
                                                </div>
                                                <span class="rt-span" @click.stop="deletePhoto(photo)">
                                                    <RtIcon variant="light" icon="circle-xmark rt-xmark-icon text-danger"  :size="18"/>
                                                </span>
                                                <b-img :src="photo.image_thumb_url" thumbnail :alt="'Photo' + index" class="rt-img" :class="{'rt-img-active': photo.BuildingCoverImage}"></b-img>
                                            </div>
                                </draggable>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="buildingData.BuildingCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ buildingData.BuildingCreateTime | date}}
                                        </small>
                                    </div>
                                    <div v-if="buildingData.BuildingLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ buildingData.BuildingLastEditDate | date}}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'buildings'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                    {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>
                <b-tab
                    @click="changeTab('documents')"
                >
                    <template #title>
                        <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                            <div class="ren-title-img">
                            <RtIcon :size="21" variant="light" icon="briefcase"/>
                            </div>
                            <div>
                            <p class="m-0">
                                <strong>{{ i18nT(`Documents`)}}</strong>
                            </p>
                            <p v-if="false" class="m-0 text-secondary">
                                {{ i18nT(`Type, noms, addresse`)}}
                            </p>
                            </div>
                        </div>
                    </template>
                    <b-card no-body>
                        <b-row class=" mr-1 ml-1 mb-2 pb-2 border-bottom">
                            <b-col cols="12"
                            >
                                <h2 class="mb-2 mt-2">
                                    {{i18nT(`Documents`)}}
                                </h2>
                            </b-col>
                            <b-col cols="12"
                                class="pt-1 pl-5"
                            >
                                <div
                                    v-for="(item,index) in documents"
                                    :key="`document` + index"
                                    class="pb-1 pt-1"
                                    >
                                    <document-card  :document="item" :editable="item['_can_edit']" @edit="onEditDocument" @delete="onDeleteDocument(item,index)"/>
                                </div>
                                <div class="pt-1 mb-1">
                                    <b-button
                                        variant="outline-primary"
                                        @click="openDocumentModal('document')"
                                        >
                                        <rt-icon icon="plus-circle" variant="light" :size="14"/>
                                        {{i18nT(`Ajouter un document`)}}
                                    </b-button>
                                </div>
                                <small>{{i18nT(`Vous pouvez ajouter plusieurs documents. Ces documents seront sauvegardés dans la rubrique Documents.`)}}</small>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6" class="text-muted">
                                <div  class="m-1 pl-1">
                                    <div v-if="buildingData.BuildingCreateTime">
                                        <span>
                                            <rt-icon icon="calendar-plus" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Créé le`) }} {{ buildingData.BuildingCreateTime | date}}
                                        </small>
                                    </div>
                                    <div v-if="buildingData.BuildingLastEditDate">
                                        <span>
                                            <rt-icon icon="calendar-pen" variant="light"/>
                                        </span>
                                        <small>
                                            {{ i18nT(`Modifié le `) }} {{ buildingData.BuildingLastEditDate | date}}
                                        </small>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="6">
                                <div class="text-right m-1">
                                    <b-button
                                        variant="outline-secondary"
                                        class="mb-1 mr-1"
                                        :to="{ name: 'buildings'}"
                                    >
                                        {{i18nT(`Annuler`)}}
                                    </b-button>
                                    <b-button
                                        variant="primary"
                                        class="mb-1"
                                        @click.prevent="validationForm"
                                    >
                                    {{i18nT(`Sauvegarder`)}}
                                    </b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-tab>
            </b-tabs>
        </validation-observer>
        

        <modal-add-document :documents="allDocuments" location="buildings" :document-temp-id="documentTempId" @add="addDocument" /> 

        <modal-edit-document :document="buildingDocument" />

        <modal-edit-service-charge :serviceCharge="serviceCharge" @resetModal="resetServiceChargeModal" @updateServiceCharge="updateServiceCharges"/>

        <b-modal
            id="preview"
            centered
            :hide-footer="true"
            size="lg"
        >
            <b-img
                :src="previewPhoto"
                alt="logo"
                class="mr-1"
                fluid-grow
            />
        </b-modal>



    </div>

</template>

<script>
import {
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    VBTooltip,
    BCollapse,
    BImg,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormCheckboxGroup, 
    BDropdownItem, 
    BDropdown,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import { required } from '@validations'
import RtSelect from '@core/components/RtSelect.vue'
import vSelect from 'vue-select'
import EllipsisDropdown from '@core/components/EllipsisDropdown.vue'
import DatePicker from "@core/components/DatePicker.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ModalAddDocument from "@/views/common/Documents/ModalAddDocument.vue";
import ModalEditServiceCharge from "@/views/common/ServiceCharge/ModalEditServiceCharge.vue"
import ServiceChargeCard from "@/views/common/ServiceCharge/ServiceChargeCard.vue"
import DocumentCard from "@/views/common/Documents/DocumentCard.vue";
import ModalEditDocument from "@/views/common/Documents/ModalEditDocument.vue";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import {API_KEY} from "@core/utils/constants";
import useAuth from '@/auth/useAuth';
import router from "@/router";
import draggable from 'vuedraggable'
import {dictToSelectArray} from "@core/utils/utils";


export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BRow,
        BCol,
        BCollapse,
        BImg,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BFormCheckboxGroup, 
        BDropdownItem, 
        BDropdown,
        ValidationProvider,
        ValidationObserver,
        RtSelect,
        vSelect,
        EllipsisDropdown,
        DatePicker,
        ModalAddDocument,
        DocumentCard,
        ModalEditDocument,
        ModalEditServiceCharge,
        ServiceChargeCard,
        VueDropzone: vue2Dropzone,
        draggable
    },
    directives: {
        'b-tooltip': VBTooltip
    },
    data() {
        return {
            tabIndex: 0,
            tabNames: ['general-info', 'lots', 'additional-info', 'financial-info', 'keys-and-digital', 'service-charge', 'pictures', 'documents'],
            buildingData: {},
            properties: [],
            buildingProperties: [{}],
            propertyThousandths: {},
            serviceCharges: [],
            serviceCharge: {},
            required,
            countries: [],
            selectedExtras: [], // Must be an array reference!
            previewPhoto: null,
            extras: [],
            keysAndCodes: [{}],
            documents: [],
            allDocuments: [],
            buildingDocument: null,
            dropzoneOptions: {
                // TODO add correct url once it's available //
                url: process.env.VUE_APP_API_URL+'/landlord/buildings/photo' + (router.currentRoute.params.id ? `?BuildingID=${router.currentRoute.params.id}` : ''),
                addRemoveLinks: true,
                autoProcessQueue: true,
                clickable: true,
                thumbnailMethod: 'contain',
                thumbnailWidth: 90,
                dictDefaultMessage: this.i18nT(`Déposez des fichiers ici ou cliquez pour charger.`),
                dictRemoveFile: this.i18nT(`Retirer`),
                dictFileTooBig: this.i18nT(`Le fichier est trop volumineux ({{filesize}} Mo). La taille maximale du fichier est : {{maxFilesize}} Mo`),
                dictInvalidFileType: this.i18nT(`Seuls les PDF et JPG sont autorisés`),
                dictCancelUpload: this.i18nT(`Annuler`),
                dictCancelUploadConfirmation: this.i18nT(`Êtes-vous sûr de vouloir annuler ce téléchargement?`),
                dictMaxFilesExceeded: this.i18nT(`Vous pouvez joindre jusqu'à 10 fichiers`),
                maxFilesize: 5,
                maxFiles: 10,
                parallelUploads: 1,
                uploadMultiple: false,
                acceptedFiles: 'image/*,application/pdf',
                paramName: 'BuildingImage',
                headers: {
                    'Api-Key': API_KEY,
                    'Auth-Key': useAuth.getToken(),
                },
            },
            photos: [],
            currentBuildingId: router.currentRoute.params.id,
            curIndexServiceCharge: null,
            documentTempId: "",
            curDocumentIndex: null,
        }
    },
    created() {
        const tab = this.$route.params.tab

        if(tab){
            this.tabIndex = this.tabNames.indexOf(tab)
        }

        this.$http.get('landlord/buildings/new').then(({data}) => {

            this.documentTempId = data.data.DocumentTempID

            this.countries = data.data.countries.map(country => {
                return {
                    value: country.id,
                    text: country.label,
                }
            })
            this.extras = data.data.extras.map(extra => {
                return {
                    value: extra.id,
                    text: extra.label
                }
            })
            this.properties = data.data.all_properties.map(property => {
                return {
                    value: property.id,
                    text: property.label,
                    disabled: false
                }
            })
            this.allDocuments =  data.data['all_documents']

        })
        .then(() => {
            if(this.currentBuildingId) {
                this.$http.get(`landlord/buildings?id=${this.currentBuildingId}&edit_data=1`)
                .then(({data}) => {
                    console.log('Buildins data: ', data)
                    this.buildingData = data.data.building
                    this.documents = data.data.documents
                    this.photos = data.data.building.BuildingImages
                    this.selectedExtras = data.data.building_extras
                    this.buildingProperties = data.data['building_properties'].map(item => {
                        return {
                            value: item.id,
                            text: item.label,
                        }
                    })

                    this.disableSelectArray('buildingProperties', 'properties')

                    this.propertyThousandths = Object.assign({}, ...data.data['property_thousandths'].map(item => {
                        return {
                            [+item.PropertyID]: item.PropertyThousandthFormula
                        }
                    }))
                    this.serviceCharges = data.data['service_charges'].length > 0 ? data.data['service_charges'] : []
                    this.keysAndCodes = data.data['keys_access_codes'].length > 0 ? data.data['keys_access_codes'] : [{}]
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(err),
                            icon: 'InfoIcon',
                            variant: 'danger'
                        }
                    })
                })
            }
        })
        .catch((err) => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: this.errorMessage(err),
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                }
            })
        })
        
    },
    methods: {
        changeTab(tab){

            this.tabIndex = this.tabNames.indexOf(tab)

            let currentPath

            if(!this.$route.params.id){
                currentPath='/landlord/buildings/new'
            } else {
                currentPath=`/landlord/buildings/${this.$route.params.id}/edit`
            }

            history.pushState({}, null,  currentPath+ "/" + encodeURIComponent(tab));
        },
        queCompleteMultiple() {
            let self = this;

            setTimeout(function () {
                self.$refs.photoDropzone.removeAllFiles();
            }, 4000);
        },
        dropzoneErrorMultiple(message){
            console.log("message: ", message)
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                timeout: 9000,
                props: {
                    title: this.i18nT(message.previewElement.childNodes[7].innerText),
                    text: message.upload.filename,
                    icon: 'InfoIcon',
                    variant: 'danger',
                    timeout: 20000,
                },
            })
        },
        dropzoneSuccessMultiple: function (files, response) {
            this.photos.push({...response.data})
        },
        disableSelectArray(array, options) {
            this[options].forEach(option => option.disabled = false)

            console.log(this[array])
            this[array].map(item => {
                for (let i = 0; i < this[options].length; i++) {
                    if(item.value && this[options][i].value === item.value) {
                        this[options][i].disabled = true
                    }
                } 
            })
        },
        openPreviewModal(photo) {
            this.previewPhoto = photo.image_url
            this.$bvModal.show('preview')
        },
        openDocumentModal(){
            this.buildingDocument = null;
            this.curDocumentIndex = null;
            this.$bvModal.show('modal-add-document');
        },
        addDocument(newDocument) {
            if(this.curDocumentIndex != null){
                this.documents[this.curDocumentIndex] = {...newDocument};
                this.documents=[...this.documents];
                this.curDocumentIndex =  null;
                return;
            }
            const checkExisting = this.documents.find(document => document.DocumentID === newDocument.DocumentID);
            if(checkExisting){
                const index = this.documents.indexOf(checkExisting);
                this.documents[index] = {...newDocument};
                this.documents=[...this.documents];
                return;
            }
            this.documents.push({...newDocument});
            this.curDocumentIndex = null;
            this.buildingDocument = null;
        },
        onDeleteDocument(document, index) {
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Veuillez confirmer la suppression.?`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Confirmer`),
                cancelButtonText: this.i18nT(`Annuler`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if(result.value) {

                    const id = this.currentBuildingId ? `BuildingID=${this.currentBuildingId}` : `DocumentTempID=${this.documentTempId}`;

                    if(!this.currentBuildingId && !document['_new_upload']) {
                            this.documents.splice(index, 1);
                            this.documents = [...this.documents];
                            this.curDocumentIndex=null;
                            return
                    }

                    this.$http.delete(`landlord/buildings/document?${id}&DocumentID=${document.DocumentID}`).then(()=>{
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Succès!`),
                            text: this.i18nT(`Supprimé avec succès!`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                            this.documents.splice(index, 1);
                            this.documents = [...this.documents];
                            this.curDocumentIndex=null;
                    })
                }
            }).catch(err => {
                this.$swal({
                    icon: 'error',
                    title: 'Error!',
                    text: this.errorMessage(err),
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
            })
        },
        setCoverImage(photo) {
            if(photo.BuildingCoverImage) {
                return
            } 

            const url = this.currentBuildingId ?
                `cover?BuildingID=${this.currentBuildingId}&CoverImageID=${photo.BuildingImageID}` :
                `tempCover?index=${photo.index}`;

            this.$http.post(`landlord/buildings/${url}`)
            .then(() => {
                this.photos.forEach(p => p.BuildingCoverImage = 0)
                photo.BuildingCoverImage = 1
            })
        },
        deletePhoto(photo) {
            const isCoverImage = photo.BuildingCoverImage ? true : false

            const url = this.currentBuildingId ?
                `photo?BuildingID=${this.currentBuildingId}&BuildingImageID=${photo.BuildingImageID}` :
                `tempPhoto?index=${photo.index}`;

            this.$http.delete(`landlord/buildings/${url}`)
            .then(({data}) => {
                this.photos = [...data.data]
                if(isCoverImage && this.photos.length > 0) {
                    this.setCoverImage(this.photos[0])
                }
            })
        },
        updatePhotoOrder(event) {

            const url = this.currentBuildingId ?
                `positions?BuildingID=${this.currentBuildingId}&image_ids=${this.photos.map(photo => photo.BuildingImageID).join()}` :
                `tempPositions?indexes=${this.photos.map(photo => photo.index).join()}`;

            this.$http.post(`landlord/buildings/${url}`)
            .then(() => {
                if(!this.currentBuildingId) {
                    this.photos = this.photos.map((photo, index) => {
                        return {
                            ...photo,
                            index: index
                        }
                    })  
                }
            })



            console.log('photos: ', this.photos)

        },
        editServiceCharge(item, index) {
            this.serviceCharge = item
            this.curIndexServiceCharge = index;
            this.$bvModal.show('modal-service-charge')
        },
        deleteServiceCharge(item){
            if(item.id){
                const index = this.serviceCharges.findIndex(charge => charge.id === item.id)
                this.serviceCharges[index].delete = 1
                this.serviceCharges=[...this.serviceCharges]
            } else {
                this.serviceCharges = this.serviceCharges.filter(charge => charge !== item)
            }
        },
        resetServiceChargeModal() {
            this.serviceCharge = null
            this.curIndexServiceCharge = null;
        },
        updateServiceCharges(item){
            if(item.id){
                const index = this.serviceCharges.findIndex(charge => charge.id === item.id)
                this.serviceCharges[index] = item
            } else if(this.curIndexServiceCharge){
                this.serviceCharges[this.curIndexServiceCharge] = item
            } else{
                this.serviceCharges.push(item)
            }
            this.resetServiceChargeModal();
        },
        addKeyAndCode() {
            this.keysAndCodes.push({})
        },
        removeKeyAndCode(index) {
            if(this.keysAndCodes[index].BuildingKeyID){
                this.keysAndCodes[index].BuildingKeyDelete = 1
                this.keysAndCodes = [...this.keysAndCodes];
            } else{
                this.keysAndCodes.splice(index, 1)
            }

        },
        addProperty() {
            this.buildingProperties.push({})
        },
        removeProperty(index) {
            delete this.propertyThousandths[this.buildingProperties[index].value]
            this.buildingProperties.splice(index, 1)
        },
        addNewDocument(doc) {
            this.documents.push(doc)
        },
        onEditDocument(document, index){
            this.buildingDocument = document;
            this.curDocumentIndex = index;
            this.$bvModal.show('modal-edit-document')
          },
        onDelete() {
            this.$swal({
                title: this.i18nT(`Avertissement`),
                text: this.i18nT(`Veuillez confirmer la suppression du locataire.`),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: this.i18nT(`Annuler`),
                confirmButtonText: this.i18nT(`Confirmer`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`landlord/buildings?id=${this.currentBuildingId}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Succès!`),
                                text: this.i18nT(`Supprimé avec succès!`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.$router.push({ name: 'buildings' })
                        })
                }
            }).catch(err => {
                this.$swal({
                    icon: 'error',
                    title: 'Error!',
                    text: this.errorMessage(err),
                    customClass: {
                        confirmButton: 'btn btn-success'
                    }
                })
            })
        },
        validationForm() {
            this.$refs.simpleRules.validateWithInfo().then(({isValid, errors, fields}) => {
                if (isValid) {
                    const formData = new FormData()

                    if (this.$router.currentRoute.params.id) {
                        formData.append('id', this.$router.currentRoute.params.id)
                    }
                    else {
                        formData.append('DocumentTempID', this.documentTempId)
                    }

                    //  GENERAL INFO

                    formData.append('BuildingAddress', this.buildingData.BuildingAddress);
                    formData.append('BuildingActualAddress', this.buildingData.BuildingActualAddress);
                    formData.append('BuildingActualAddress2', this.buildingData.BuildingActualAddress2);
                    formData.append('BuildingActualCity', this.buildingData.BuildingActualCity);
                    formData.append('BuildingActualZip', this.buildingData.BuildingActualZip);
                    formData.append('BuildingActualState', this.buildingData.BuildingActualState);
                    formData.append('BuildingActualCountry', this.buildingData.BuildingActualCountry);
                    formData.append('BuildingSize', this.buildingData.BuildingSize);
                    formData.append('BuildingConstructionYear', this.buildingData.BuildingConstructionYear);
                    formData.append('BuildingComments', this.buildingData.BuildingComments);
                    formData.append('BuildingNotes', this.buildingData.BuildingNotes);

                    // TODO ADD FORM DATA

                    // UNITS

                    if(this.buildingProperties.length>0){
                        this.buildingProperties.forEach((property, index) => {
                            formData.append(`PropertyID[${index}]`, property.value)
                            formData.append(`PropertyThousandthFormula[${index}]`, this.propertyThousandths[property.value] ? this.propertyThousandths[property.value] : "")
                        })
                    }

                    // ADDITIONAL INFO

                        this.selectedExtras.forEach(extra => {
                            formData.append('BuildingExtras[]', extra)
                        });
                    
                    // FINANCIAL INFO

                    formData.append('BuildingAcquisitionDate', this.buildingData.BuildingAcquisitionDate? this.buildingData.BuildingAcquisitionDate : null)
                    formData.append('BuildingInitialPrice', this.buildingData.BuildingInitialPrice)
                    formData.append('BuildingAcquisitionCosts', this.buildingData.BuildingAcquisitionCosts)
                    formData.append('BuildingAnnualTax', this.buildingData.BuildingAnnualTax)


                    // KEYS-AND-DIGITAL

                    if(Object.keys(this.keysAndCodes[0]).length > 0) {
                        this.keysAndCodes.forEach((key, index) => {

                            formData.append(`BuildingKeyID[${index}]`, key.BuildingKeyID ? key.BuildingKeyID : 0);
                            formData.append(`BuildingKeyDescription[${index}]`, key.BuildingKeyDescription)
                            formData.append(`BuildingKeyNumber[${index}]`, key.BuildingKeyNumber)
                            formData.append(`BuildingKeyQuantity[${index}]`, key.BuildingKeyQuantity)
                            formData.append(`BuildingKeyComments[${index}]`, key.BuildingKeyComments)

                            if(key.BuildingKeyDelete) {
                                formData.append(`BuildingKeyDelete[${index}]`, key.BuildingKeyDelete)
                            }
                        });
                    }

                    // SERVICE CHARGE

                    if(this.serviceCharges.length>0) {
                        this.serviceCharges.forEach((charge, index) => {
                            formData.append(`SplitChargeID[${index}]`, charge.id ? charge.id : 0)
                            formData.append(`SplitChargeTitle[${index}]`, charge.text)
                            formData.append(`SplitChargeDelete[${index}]`, charge.delete)
                            if(this.serviceCharges.length>0) {
                                charge['charges_data'].forEach((item, i) => {
                                    formData.append(`SplitPropertyID[${index}][${i}]`, item['property_id'])
                                    formData.append(`SplitThousandthFormula[${index}][${i}]`, item['thousandth_formula'])
                                })
                            }
                        });
                    }

                    // PHOTOS

                    // DOCUMENTS

                    if(this.documents.length>0){
                        const documentIDs = this.documents.map(document=> document.DocumentID).join(',');
                        formData.append('DocumentID', documentIDs);
                    }

                    // formData.append('documents', JSON.stringify(this.documents))

                    // this.$http.post('/axel', formData)

                    this.$http.post('landlord/buildings', formData).then(({data}) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.successMessage(data),
                                icon: 'InfoIcon',
                                variant: 'success'
                            }
                        })
                        this.$router.push({name: 'buildings'})
                    })
                    .catch(err => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'InfoIcon',
                                variant: 'danger'
                            }
                        })
                    })

                }else {
                    this.changeTab('general-info')
                    let y = 1000000
                    let prevY = 1000000
                    for (const key in errors) {
                        if (errors[key].length > 0) {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: errors[key][0],
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                            const el = document.getElementById(fields[key].id)
                            prevY = el.getBoundingClientRect().top + window.scrollY - 150
                            if (prevY <= y) y = prevY
                        }
                    }
                    if (y < 1000000) {
                        window.scrollTo({ top: y, behavior: 'smooth' })
                    }
                }
            })
        }
    }
}


</script>

<style lang="scss">



.nav-item > a {
    color: inherit;
}

.rt-custom-checkbox{
    width: 150px;
}

.rt-img-container {
    position: relative !important;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
    width: 100%;
    max-width: 250px;
    min-width: 150px;
    cursor: grab;
}

//.rt-img {
//    max-height: 200px !important;
//}

.rt-img-container:hover {
  transform: translateY(-2.5px);
}

.rt-span {
    height: 0px;
}

.rt-star-icon {
    position: absolute;
    top: 13px;
    right: 35%;
    color: var(--primary); 
    cursor: pointer;
    background-color: rgba(255,255,255,0.8);
    border-radius: 50px;
    padding: 2px;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}

.rt-img-container:hover .rt-star-icon {
    opacity: 1;
}

.rt-img-active.img-thumbnail  {
    border: 2px solid var(--primary) !important;
    /* box-shadow: rgba(var(--primary), 0.7) 0px 7px 29px 0px; */
}

.rt-xmark-icon {
    position: absolute;
    top: 13px;
    right: -35%;
    cursor: pointer;
    background-color: rgba(255,255,255,0.4);
    padding: 2px;
    border-radius: 50px;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}

.rt-img-container:hover .rt-xmark-icon {
    opacity: 1;
}

.nav-item > a {
    color: inherit;
}

.nav-item > a:hover {
    color: inherit;
}


</style>